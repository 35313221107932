<template>
  <div class="app" @click="handleOutsideClick">
    <div v-show="showDeleteModal && !isMultiSelectMode" class="modal-overlay" @click.stop></div>

    <div class="chat-container" v-if="isAiEnabled" ref="chatContainer" :key="triggerRerender">
      <div id="copySuccess" class="copy-success">复制成功</div>
      <!-- AI 回复 -->
      <div v-for="(message, index) in messages" :key="index" :class="`message ${message.type}`">
        <img v-if="isMultiSelectMode && message.type === 'right'"
          :src="selectedMessages.includes(index) ? require('@/assets/checkRadio.png') : require('@/assets/radio.png')"
          class="radio-icon-w-r" @click="toggleSelection(index)" />
        <img v-if="isMultiSelectMode && message.type !== 'right'"
          :src="selectedMessages.includes(index) ? require('@/assets/checkRadio.png') : require('@/assets/radio.png')"
          class="radio-icon-w-l" @click="toggleSelection(index)" />

        <img :src="message.type === 'right' ? userAvatarUrl : require('@/assets/left-avatar.png')" />
        <div class="text" @touchstart="handleTouchStart($event, index)" @touchend="handleTouchEnd"
          @mousedown="handleTouchStart($event, index)" @mouseup="handleTouchEnd">

          <div v-html="message.text"></div>
          <a v-if="message.type === 'left'" class="copy-link">
            <div style="display:flex" @click.prevent="copyMessage(message.text)">
              <div><img src="@/assets/copy-icon.png" class="copy-icon" /></div>
              <div>复制内容</div>
            </div>
            <template v-if="textStartIndex != index">
              <img src="@/assets/start.png" v-if="audioStartIndex != index"
                @click.prevent="startAudio(message.text, index)" class="copy-icon-audio" />
              <img src="@/assets/stopAudio.png" v-else @click.prevent="endAudio(message.text, index)"
                class="copy-icon-audio" />
            </template>
          </a>
          <div :class="['tooltip', { 'tooltip-top': tooltipAtTop }]"
            v-if="showTooltip && currentMessageIndex === index && message.type != 'initial'">
            <div class="tooltip-button" @click.prevent="copyMessage(message.text)">
              <div><img src="@/assets/copy-w.png" /></div>
              <div>复制</div>
            </div>
            <div class="tooltip-button" @click.prevent="showDelete()">
              <div><img src="@/assets/shanchu.png" /></div>
              <div>删除</div>
            </div>
            <div class="tooltip-button" @click.prevent="toggleMultiSelectMode">
              <div><img src="@/assets/dx.png" /></div>
              <div>多选</div>
            </div>

            <!-- <button @click="deleteMessage(index)">删除</button>
            <button @click="selectMessage(index)">多选</button> -->
          </div>
        </div>
      </div>
    </div>
    <div class="mic-status" v-show="showMicStatus">
      <i :lass="'icon'"></i>
      <div class="mic-con">
        <img src="@/assets/mic.png" />
        <div class="wave">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>

      <p :style="false ? 'background-color: #AA0000' : ''">手指上滑取消</p>
    </div>

    <!-- 底部功能区 -->
    <div class="footer" v-if="isAiEnabled" style="padding-bottom: 20px;">
      <template v-if="!waitingForReply">
        <img class="record-button" v-if="!isStartL" src="@/assets/audio.png" @click="toggleRecording" />
        <img class="record-button" v-else src="@/assets/wr.png" @click="toggleStartL" />
      </template>
      <input @focus="refreshChatContainer" @blur="refreshChatContainer" v-show="!waitingForReply && !isStartL" type="text"
        ref="textInput" id="textInput" class="input-box-input" placeholder="输入消息" style="height: 40px;" @keyup.enter="sendQuestion"
        v-model="userInput" />
      <button class="send-button" v-show="!waitingForReply && !isStartL" @click="handleSendEvent"
        @touchend="handleSendEvent">发送</button>

      <span v-show="waitingForReply" class="input-box waiting-reply" style="line-height: 35px;">AI回复中...</span>
      <!-- <button v-show="isStartL" class="input-box" @click="toggleRecordingB">{{ recordingButtonText }}</button> -->
      <button v-show="isStartL" class="input-box" style="height: 47px;" @touchmove="handleTouchMove"
        @touchstart="startToggleRecordingB" @mousedown="startToggleRecordingB" @mouseup="endToggleRecordingB"
        @touchend="endToggleRecordingB"> {{ recordingButtonText }} </button>
    </div>
    <div class="AiEnabled" v-else>
      <img src="@/assets/lujing.png" />
      <div>功能暂时无法使用!</div>
    </div>

    <div v-if="showDeleteModal" class="delete-modal">
      <div class="delete-options">
        <div class="delete-option">是否删除该条消息</div>
        <div class="delete-option confirm" @click="confirmDelete">确定</div>
        <div class="delete-option cancel" @click="cancelDelete">取消</div>
      </div>
    </div>


  </div>
</template>

<script>
import CryptoJS from "crypto-js";
import { hex_md5 } from "@/utils/md5.js";
import TTSClient from "@/utils/code.js";
// import { speakXunfei, speakXunfeiStop, initXunfeiAudio } from "@/utils/audio.js";
import TransWorker from '@/utils/transcode.worker.js?worker'
import {
  Base64
} from 'js-base64'
// import ChatClient from '@/utils/spark.js';

let transWorker = new TransWorker()

export default {
  name: "App",

  created() {
    const urlParams = new URLSearchParams(window.location.search);
    this.userId = urlParams.get('user_id');

    this.fetchConfig().then(() => {
      this.ttsClient = new TTSClient({
        appId: this.ttsAppId,
        apiSecret: this.ttsApiSecret,
        apiKey: this.ttsApiKey,
        uid: this.userId,
        webUrl: this.xf_url,
        qa:this.qa
      });
      this.ttsClient.onMessage = this.handleMessage;
      this.ttsClient.onOver = this.overTextContent;
      this.init();
      //initXunfeiAudio(this.ttsAppId, this.ttsApiKey, this.ttsApiSecret);
      this.initTTSRecord();
      const storedHistory = localStorage.getItem(`conversationHistory-${this.userId}`);
      if (storedHistory) {
        let history = JSON.parse(storedHistory);
        history.filter(message => message.type !== 'initial')
          .forEach(msg => this.addMessage(msg.content, msg.role === 'user' ? 'right' : 'left'));
      }
      this.addInitialMessages();
    });
  },

  mounted() {
    //初始化
    this.init();
    this.addInputFocusListener();
  },
  updated() {
    const links = document.querySelectorAll('.problem-link');
    links.forEach(link => {
      link.addEventListener('click', (e) => {
        e.preventDefault();
        const problem = e.target.getAttribute('data-problem');
        this.handleProblemClick(problem);
      });
    });
  },
  beforeDestroy() {
    this.uninit();
  },
  data() {
    return {
      qa:[],
      selectedMessages: [], // 存储选中消息的索引
      isMultiSelectMode: false, // 控制多选模式
      touchStartTimer: null,
      showDeleteModal: false,
      tooltipAtTop: false, // 用于控制tooltip类的标记
      showTooltip: true,
      currentMessageIndex: null,
      touchStartTime: 0,
      longPressTime: 1000, // 长按时间阈值，例如500毫秒

      lastClickTime: 0,
      debounceInterval: 1000,
      audioStartIndex: -1,
      textStartIndex: -1,
      hasRecordPermission: false,
      touchStartY: 0,
      showMicStatus: false,
      audioStr: "",
      userId: null,
      userAvatarUrl: "",
      isAiEnabled: true,
      triggerRerender: 0,
      currentRecordingIndex: null,
      isRecording: false,
      recordingButtonText: '按住说话',
      conversationHistory: [],
      problem1: '内容1',
      problem2: '内容2',
      problem3: '内容3',
      isStartL: false,
      waitingForReply: false,

      ttsAppId: '', // 从环境变量或其他配置中获取
      ttsApiSecret: '', // 从环境变量或其他配置中获取
      ttsApiKey: '', // 从环境变量或其他配置中获取
      ttsClient: null,
      messages: [],
      userInput: '',

      //ChatGPT
      openApiKey: "xxxxxx", //ChatGPT APIKey
      configuration: null,
      openai: null,
      modelEngine: "text-davinci-003",
      chatCount: 0,

      //科大讯飞
      appid: "46bc0d37", //科大讯飞 AppID
      apiKey: "118a081cb6f7705b5b4ba82cd5b59084", //科大讯飞 APIKey
      uri: "wss://rtasr.xfyun.cn/v1/ws", // 科大讯飞地址
      result: "",
      recording: false,
      sessionID: "",

      socket: null,

      //audio stream
      audioStream: null,
      streamSource: null,
      audioContext: null,
      audioInput: null,
      audioSampleRate: 16000,
      audioBuffer: [],
      audioBufferSize: 0,
      audioSampleBits: 16,
      audioChannels: 1,

      recWorker: null,
      processer: null,

      handlerInterval: null,

      state: "none",
    };
  },

  watch: {
    // 监听 audioStr 的变化
    audioStr(newVal) {
      if (newVal !== '') { // 检查 audioStr 是否为空字符串
        this.userInput = newVal; // 如果不为空，则更新 userInput
      }
    }
  },

  methods: {
    addInputFocusListener() {
      const inputField = document.getElementById('textInput');
      if (inputField) {
        inputField.addEventListener('focus', this.handleInputFocus);
      }
    },
    handleInputFocus() {
      setTimeout(() => {
        const inputField = document.getElementById('textInput');
        if (inputField) {
          inputField.scrollIntoViewIfNeeded(true);
          inputField.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
        }
      }, 500); // 延迟时间可能需要根据实际情况调整
    },
    toggleMultiSelectMode() {
      this.showTooltip = false;
      this.isMultiSelectMode = !this.isMultiSelectMode;
      if (!this.isMultiSelectMode) {
        // 如果离开多选模式，则清空选中的消息
        this.selectedMessages = [];
      }
    },
    toggleSelection(index) {
      const selectedIndex = this.selectedMessages.indexOf(index);
      if (selectedIndex === -1) {
        // 如果当前索引不在 selectedMessages 中，则添加它
        this.selectedMessages.push(index);
        this.showDeleteModal = true;
      } else {
        // 否则从 selectedMessages 中移除它
        this.selectedMessages.splice(selectedIndex, 1);
      }
    },
    confirmDelete() {
      // 确认删除的逻辑
      this.showDeleteModal = false;

      if (this.isMultiSelectMode) {
        // 如果是多选模式
        this.selectedMessages.sort((a, b) => b - a); // 降序排序，以便从最后一个开始删除
        this.selectedMessages.forEach((index) => {
          this.messages.splice(index, 1); // 删除选中的消息
          // 更新历史记录并保存到 localStorage
          this.conversationHistory.splice(index - 1, 1);
        });
        this.selectedMessages = []; // 清空已选中的消息
      } else {
        // 单选删除的逻辑
        if (this.currentMessageIndex !== null) {
          this.messages.splice(this.currentMessageIndex, 1);
          // 更新历史记录并保存到 localStorage
          this.conversationHistory.splice(this.currentMessageIndex - 1, 1);
          this.currentMessageIndex = null;
        }
      }

      this.saveHistoryToLocal();
      this.showTooltip = false;
      this.isMultiSelectMode = false; // 重置多选模式
    },

    cancelDelete() {
    // 取消删除的逻辑
    this.showDeleteModal = false;
    
    // 如果是多选模式，清空选中的消息索引并退出多选模式
    if (this.isMultiSelectMode) {
      this.selectedMessages = [];
      this.isMultiSelectMode = false;
    }

    // 重置其他相关状态
    this.currentMessageIndex = null;
    this.showTooltip = false;
    // this.$nextTick(() => {
    //   // 重新设置 chat-container 的滚动位置
    //   const container = this.$refs.chatContainer;
    //   container.scrollTop = container.scrollHeight;
    // });
  },

    handleTouchStart(event, index) {
      const currentTarget = event.currentTarget
      // 设置计时器
      this.touchStartTimer = setTimeout(() => {

        // 获取 text 元素的位置信息
        let rect = currentTarget.getBoundingClientRect();

        // 计算距离视口底部的距离
        let distanceToBottom = window.innerHeight - rect.bottom;

        this.tooltipAtTop = distanceToBottom < 115;

        this.currentMessageIndex = index;
        this.showTooltip = true;
      }, 300); // 1秒延迟
    },


    showDelete() {
      // 确认删除的逻辑
      this.showDeleteModal = true;
      this.showTooltip = false;
    //   this.$nextTick(() => {
    //   // 重新设置 chat-container 的滚动位置
    //   const container = this.$refs.chatContainer;
    //   container.scrollTop = container.scrollHeight;
    // });

    },

    handleTouchEnd() {
      this.touchStartTime = 0;
      if (this.touchStartTimer) {
        clearTimeout(this.touchStartTimer);
        this.touchStartTimer = null;
      }
    },

    deleteMessage(index) {
      // 删除消息的逻辑
    },

    selectMessage(index) {
      // 多选消息的逻辑
    },

    getWebsocketUrl() {
      return new Promise((resolve, reject) => {
        var apiKey = this.ttsApiKey
        var apiSecret = this.ttsApiSecret
        var url = 'wss://tts-api.xfyun.cn/v2/tts'
        var host = location.host
        var date = new Date().toGMTString()
        var algorithm = 'hmac-sha256'
        var headers = 'host date request-line'
        var signatureOrigin = `host: ${host}\ndate: ${date}\nGET /v2/tts HTTP/1.1`
        var signatureSha = CryptoJS.HmacSHA256(signatureOrigin, apiSecret)
        var signature = CryptoJS.enc.Base64.stringify(signatureSha)
        var authorizationOrigin = `api_key="${apiKey}", algorithm="${algorithm}", headers="${headers}", signature="${signature}"`
        var authorization = btoa(authorizationOrigin)
        url = `${url}?authorization=${authorization}&date=${date}&host=${host}`
        resolve(url)
      })
    },

    initTTSRecord({
      speed = 50,
      voice = 50,
      pitch = 50,
      voiceName = 'xiaoyan',
      text = '',
      tte = 'UTF8',
      defaultText = '你好，请问你在做什么？嗷嗷啊啊啊啊，哈哈哈哈哈哈哈哈哈哈哈哈哈',
    } = {}) {
      this.speed = speed
      this.voice = voice
      this.pitch = pitch
      this.voiceName = voiceName
      this.text = text
      this.tte = tte
      this.defaultText = defaultText
      this.audioData = []
      this.rawAudioData = []
      this.audioDataOffset = 0
      this.status = 'init'
      this.callback = () => {
        this.endAudio();
        //console.log("finished")
      }
      transWorker.onmessage = (e) => {
        this.audioData.push(...e.data.data)
        this.rawAudioData.push(...e.data.rawAudioData)
      }
    },
    // 修改录音听写状态
    setStatus(status) {
      this.onWillStatusChange && this.onWillStatusChange(this.status, status)
      this.status = status
    },
    // 设置合成相关参数
    setParams({
      speed,
      voice,
      pitch,
      text,
      voiceName,
      tte
    }) {
      speed !== undefined && (this.speed = speed)
      voice !== undefined && (this.voice = voice)
      pitch !== undefined && (this.pitch = pitch)
      text && (this.text = text)
      tte && (this.tte = tte)
      voiceName && (this.voiceName = voiceName)
      this.resetAudio()
    },
    // 连接websocket
    connectWebSocket() {
      this.setStatus('ttsing')
      return this.getWebsocketUrl().then(url => {
        let ttsWS
        if ('WebSocket' in window) {
          ttsWS = new WebSocket(url)
        } else if ('MozWebSocket' in window) {
          ttsWS = new MozWebSocket(url)
        } else {
          alert('浏览器不支持WebSocket')
          return
        }
        this.ttsWS = ttsWS
        ttsWS.onopen = e => {
          this.webSocketSend()
          this.playTimeout = setTimeout(() => {
            this.audioPlay()
          }, 1000)
        }
        ttsWS.onmessage = e => {
          //this.result(e.data)
          let jsonData = JSON.parse(e.data)
          // 合成失败
          if (jsonData.code !== 0) {
            alert(`合成失败: ${jsonData.code}:${jsonData.message}`)
            console.error(`${jsonData.code}:${jsonData.message}`)
            this.resetAudio()
            return
          }
          transWorker.postMessage(jsonData.data.audio)

          if (jsonData.code === 0 && jsonData.data.status === 2) {
            this.ttsWS.close()
          }
        }
        ttsWS.onerror = e => {
          clearTimeout(this.playTimeout)
          this.setStatus('errorTTS')
          alert('WebSocket报错，请f12查看详情')
          console.error(`详情查看：${encodeURI(url.replace('wss:', 'https:'))}`)
        }
        ttsWS.onclose = e => {
          console.log(e)
        }
      })
    },
    // 处理音频数据
    transToAudioData(audioData) { },
    // websocket发送数据
    webSocketSend() {
      var params = {
        common: {
          app_id: this.ttsAppId, // APPID
        },
        business: {
          aue: 'raw',
          auf: 'audio/L16;rate=16000',
          vcn: this.voiceName,
          speed: this.speed,
          volume: this.voice,
          pitch: this.pitch,
          bgs: 0, // 关闭背景音
          tte: this.tte,
        },
        data: {
          status: 2,
          text: this.encodeText(
            this.text || this.defaultText,
            this.tte === 'unicode' ? 'base64&utf16le' : ''
          )
        },
      }
      this.ttsWS.send(JSON.stringify(params))
    },
    encodeText(text, encoding) {
      switch (encoding) {
        case 'utf16le': {
          let buf = new ArrayBuffer(text.length * 4)
          let bufView = new Uint16Array(buf)
          for (let i = 0, strlen = text.length; i < strlen; i++) {
            bufView[i] = text.charCodeAt(i)
          }
          return buf
        }
        case 'buffer2Base64': {
          let binary = ''
          let bytes = new Uint8Array(text)
          let len = bytes.byteLength
          for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i])
          }
          return window.btoa(binary)
        }
        case 'base64&utf16le': {
          return this.encodeText(this.encodeText(text, 'utf16le'), 'buffer2Base64')
        }
        default: {
          return Base64.encode(text)
        }
      }
    },
    // websocket接收数据的处理
    // result(resultData) {
    //   let jsonData = JSON.parse(resultData)
    //   // 合成失败
    //   if (jsonData.code !== 0) {
    //     alert(`合成失败: ${jsonData.code}:${jsonData.message}`)
    //     console.error(`${jsonData.code}:${jsonData.message}`)
    //     this.resetAudio()
    //     return
    //   }
    //   transWorker.postMessage(jsonData.data.audio)

    //   if (jsonData.code === 0 && jsonData.data.status === 2) {
    //     this.ttsWS.close()
    //   }
    // },
    // 重置音频数据
    resetAudio() {
      this.audioStop()
      this.setStatus('init')
      this.audioDataOffset = 0
      this.audioData = []
      this.rawAudioData = []
      this.ttsWS && this.ttsWS.close()
      clearTimeout(this.playTimeout)
    },
    // 音频初始化
    audioInit() {
      let AudioContext = window.AudioContext || window.webkitAudioContext
      if (AudioContext) {
        this.audioContext = new (window.AudioContext ||
          window.webkitAudioContext)();
        this.audioContext.resume();
        this.audioDataOffset = 0
      }
    },
    // 音频播放
    audioPlay() {
      this.audioContext.resume();
      this.setStatus('play')
      let audioData = this.audioData.slice(this.audioDataOffset)
      this.audioDataOffset += audioData.length
      let audioBuffer = this.audioContext.createBuffer(1, audioData.length, 22050)
      let nowBuffering = audioBuffer.getChannelData(0)
      if (audioBuffer.copyToChannel) {
        audioBuffer.copyToChannel(new Float32Array(audioData), 0, 0)
      } else {
        for (let i = 0; i < audioData.length; i++) {
          nowBuffering[i] = audioData[i]
        }
      }
      let bufferSource = this.bufferSource = this.audioContext.createBufferSource()
      bufferSource.buffer = audioBuffer
      bufferSource.connect(this.audioContext.destination)
      bufferSource.start()
      bufferSource.onended = event => {
        console.log("data source:", event);
        if (this.status !== 'play') {
          return
        }
        if (this.audioDataOffset < this.audioData.length) {
          this.audioPlay()
        } else {
          this.audioStop()

          // TODO on end
          this.callback()
        }
      }
    },
    // 音频播放结束
    audioStop() {
      this.setStatus('endPlay')
      clearTimeout(this.playTimeout)
      this.audioDataOffset = 0
      if (this.bufferSource) {
        try {
          this.bufferSource.stop()
        } catch (e) {
          console.log(e)
        }
      }
    },
    start() {
      if (this.audioData.length) {
        this.audioPlay()
      } else {
        if (!this.audioContext) {
          this.audioInit()
        }
        if (!this.audioContext) {
          alert('该浏览器不支持webAudioApi相关接口')
          return
        }
        this.connectWebSocket()
      }
    },
    stop() {
      this.audioStop()
    },

    handleSendEvent(e) {
      e.preventDefault(); // 阻止默认行为，如链接跳转或表单提交
      if (!this.waitingForReply && !this.isStartL) {
        this.sendQuestion();
      }
    },

    refreshChatContainer() {
      this.triggerRerender++;
      this.$nextTick(() => {
        const container = this.$refs.chatContainer;
        container.scrollTop = container.scrollHeight;
      });
    },

    showCopySuccess() {
      var x = document.getElementById("copySuccess");
      x.style.display = "block"; // 显示提示
      setTimeout(function () { x.style.display = "none"; }, 3000); // 3秒后自动隐藏
    },

    endAudio() {
      //speakXunfeiStop();
      this.stop();
      this.audioStartIndex = -1;
    },

    async startAudio(text, index) {
      let AudioContext = window.AudioContext || window.webkitAudioContext
      if (AudioContext) {
        this.audioContext = new (window.AudioContext ||
          window.webkitAudioContext)();
        this.audioContext.resume();
        this.audioDataOffset = 0
      }
      let names = [
        "xiaoyan",
        "aisjiuxu",
        "aisxping",
        "aisjinger",
      ]
      this.setParams({
        text,
        bgs: 0,
        speed: Number(this.xf_speed),
        voice: Number(this.xf_volume),
        pitch: Number(this.xf_pitch),
        voiceName: this.xf_vcn
      })
      this.start();
      //speakXunfei(text, this.endAudio);
      this.audioStartIndex = index;
    },

    copyMessage(text) {
      const el = document.createElement('textarea');
      el.value = text;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      this.showCopySuccess(); // 显示复制成功提示
      this.showTooltip = false;
    },

    handleOutsideClick(event) {
      // 检查点击事件的目标是否是 tooltip 或其内部的元素
      if (this.showTooltip && !event.target.closest('.tooltip')) {
        // 如果 showTooltip 为 true 且点击不在 tooltip 上，则清除 showTooltip 和 currentMessageIndex
        this.showTooltip = false;
        this.currentMessageIndex = null;
      }
      // 检查点击事件的目标是否是输入框
      if (this.$refs.textInput && !this.$refs.textInput.contains(event.target)) {
        // 使输入框失去焦点
        this.$refs.textInput.blur();
      }
    },

    estimateTokens(text) {
      const chineseCharacters = text.match(/[\u3400-\u9FBF]/g) || [];
      const otherCharacters = text.replace(/[\u3400-\u9FBF]/g, '');
      return Math.ceil(chineseCharacters.length * 1.5 + otherCharacters.split(' ').length * 0.8);
    },

    saveHistoryToLocal() {
      localStorage.setItem(`conversationHistory-${this.userId}`, JSON.stringify(this.conversationHistory));
    },

    trimHistoryToTokensLimit(history, limit) {
      let totalTokens = 0;
      const trimmedHistory = [];
      for (let i = history.length - 1; i >= 0; i--) {
        const message = history[i];
        const tokens = this.estimateTokens(message.content);
        if (totalTokens + tokens > limit) break;
        totalTokens += tokens;
        trimmedHistory.unshift(message);
      }
      return trimmedHistory;
    },


    async fetchConfig() {
      try {
        const formData = new FormData();
        formData.append('user_id', this.userId);

        const response = await fetch('https://admin.lxfapp.com/api/index/xunfei', {
          method: 'POST',
          body: formData,
        });


        const data = await response.json();
        if (data.code === 1 && data.data) {
          this.apiKey = data.data.sfssyy_apikey;
          this.ttsApiKey = data.data.xf_apikey;
          this.ttsApiSecret = data.data.xf_apisecret;
          this.ttsAppId = data.data.xf_appid;
          this.appid = data.data.xfssyy_appid;
          this.problem1 = data.data.problem1;
          this.problem2 = data.data.problem2;
          this.problem3 = data.data.problem3;
          this.userAvatarUrl = data.data.avatar;
          this.isAiEnabled = data.data.isai === "1";
          // 处理新字段
          this.xf_vcn = data.data.xf_vcn;
          this.xf_speed = data.data.xf_speed;
          this.xf_volume = data.data.xf_volume;
          this.xf_pitch = data.data.xf_pitch;
          this.xf_url = data.data.xf_url;
          this.qa = data.data.qa;
        }
      } catch (error) {
        console.error('Error fetching configuration:', error);
      }
    },

    handleProblemClick(problem) {
      const currentTime = new Date().getTime();
      if (currentTime - this.lastClickTime < this.debounceInterval) {
        console.log("Click too fast, ignoring.");
        return; // 如果点击过快，则忽略此次点击
      }
      this.lastClickTime = currentTime;
      this.userInput = problem;
      this.sendQuestion();
    },

    addInitialMessages() {
      const initialMessage = `Hi，我是小蓝，您的智能助理，有什么我能帮到您的?我可以高效完成各领域认知需求，为您工作与学习上提供帮助，还能随时和您交流。<br>您可以尝试对我说:<br>
      <a href="#" class="problem-link" data-problem="${this.problem1}">${this.problem1}</a><br>
      <a href="#" class="problem-link" data-problem="${this.problem2}">${this.problem2}</a><br>
      <a href="#" class="problem-link" data-problem="${this.problem3}">${this.problem3}</a>`;
      this.messages.unshift({ text: initialMessage, type: 'initial' });
      this.$nextTick(() => {
        const links = document.querySelectorAll('.problem-link');
        links.forEach(link => {
          link.addEventListener('click', (e) => {
            e.preventDefault();
            const problem = e.target.getAttribute('data-problem');
            this.handleProblemClick(problem);
          });
        });
      });
    },


    toggleRecording() {
      this.requestRecordPermission();
      this.isStartL = true;
      this.recordingButtonText = '按住说话';
    },

    handleTouchMove(event) {
      const touchCurrentY = event.touches[0].clientY;
      if (this.touchStartY - touchCurrentY > 50) { // 50px 向上滑动距离阈值
        //this.shouldCancelRecording = true;
        this.endToggleRecordingB();
      }
    },


    startToggleRecordingB() {
      //event.preventDefault();  // 阻止默认行为
      if (!this.isRecording) {
        this.isRecording = true;
        // 启动录音逻辑
        this.startRecording();
        this.recordingButtonText = '结束说话'
        this.touchStartY = event.touches[0].clientY;
      }
    },

    endToggleRecordingB() {
      this.isRecording = false;
      // 启动录音逻辑
      this.isStartL = false;
      this.stopRecording();
    },


    toggleRecordingB() {
      this.isRecording = !this.isRecording;
      if (this.isRecording) {
        // 启动录音逻辑
        this.startRecording();
        this.recordingButtonText = '结束说话'
      } else {
        // 停止录音逻辑
        this.stopRecording();
        this.isStartL = false;
      }
    },

    toggleStartL() {
      this.isStartL = false;
      this.stopRecording();
    },


    startTTS() {
      // 设置消息处理回调
      this.ttsClient.start();
    },
    async sendQuestion() {
      if (!this.userInput.trim() || this.waitingForReply) return;
      const isConnected = await this.ttsClient.start();
      if (!isConnected) {
        alert('ai连接失败');
        return;
      }
      this.addMessage(this.userInput, 'right');
      const trimmedHistory = this.trimHistoryToTokensLimit(this.conversationHistory, 8192);
      this.ttsClient.webSocketSend(trimmedHistory);
      this.userInput = '';
      this.waitingForReply = true;
    },

    overTextContent() {
      this.waitingForReply = false; // 收到回复
      this.textStartIndex = -1;
    },

    handleMessage(message, type) {
      if (type === 'new') {
        this.textStartIndex = this.messages.length;
        this.addMessage(message, 'left');
      } else if (type === 'update') {
        let lastIndex = this.messages.length - 1;
        this.messages[lastIndex].text += message;
        this.conversationHistory[this.conversationHistory.length - 1].content += message; // 同时更新历史记录
        this.saveHistoryToLocal();
        this.refreshChatContainer();
      }
    },
    addMessage(text, type) {
      this.messages.push({ text, type });
      if (type !== 'initial') { // 初始消息不添加到历史中
        this.conversationHistory.push({ role: type === 'left' ? 'assistant' : 'user', content: text });
        this.saveHistoryToLocal();
      }
      this.$nextTick(() => {
        const container = this.$refs.chatContainer;
        container.scrollTop = container.scrollHeight;
      });

    },
    resetDots() {
      this.dots = []
    },
    startRecording() {
      this.showMicStatus = true;
      this.recording = true;
      //开始采集音频数据
      console.log("start to capture audio data ......");
      this.captureAudio();
    },

    async requestRecordPermission() {
      try {
        if (!this.hasRecordPermission) {
          const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
          this.mediaRecorder = new MediaRecorder(stream);
          stream.getTracks().forEach(track => track.stop());
          this.hasRecordPermission = true;
        }
      } catch (error) {
        alert('无法录音')
        console.error('录音权限被拒绝:', error);
        this.hasRecordPermission = false;
      }
    },

    async stopRecording() {
      //关闭音频
      setTimeout(() => {
        this.closeAudio();
        this.audioStr = "";
      }, 2000); // 1000 毫秒等于 1 秒
      this.currentRecordingIndex = null; // 重置索引
      //this.userInput = this.audioStr;
      this.showMicStatus = false;
      // const isConnected = await this.ttsClient.start();
      // let lastIndex = this.messages.length - 1;
      // const trimmedHistory = this.trimHistoryToTokensLimit(this.conversationHistory, 8192);
      // this.ttsClient.webSocketSend(trimmedHistory);
      // 在此处调用 webSocketSend 发送累加的数据
    },
    closeSocket() {
      //关闭websocket
      clearInterval(this.handlerInterval);
      this.socket.close();
      this.socket = null;
    },
    init() {
      console.log("initialize ......");
      this.state = "init";

      this.createWorker();
    },
    uninit() {
      console.log("uninit......");
      this.state = "terminal";

      this.closeAudio();
      this.recWorker.terminate();
    },
    createWorker() {
      this.recWorker = new Worker(
        new URL("./workers/worker", import.meta.url),
        { type: "module" }
      );

      this.recWorker.onmessage = (event) => {
        // this.result += event.data;
        this.audioBuffer.push(...event.data.buffer);
      };

      this.recWorker.onerror = function (event) {
        console.log("=======>", event.message, event.filename, event.lineno);
        this.recorder.terminate();
        if (!this.isRecording) {
          this.toggleRecordingB();
        }
      };
    },
    captureAudio() {
      if (
        this.state === "init" ||
        this.state === "end" ||
        this.state === "pause"
      ) {
        this.state = "ing";

        this.audioContext = new (window.AudioContext ||
          window.webkitAudioContext)();
        if (this.audioContext) {
          this.processer = this.audioContext.createScriptProcessor(0, 1, 1);
          console.log("create processer......");
        }

        console.log("start to capure audio data ...");

        //采集音频数据
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
          navigator.mediaDevices
            .getUserMedia({ audio: true, video: false })
            .then(
              (stream) => {
                this.audioStream = stream;

                this.streamSource =
                  this.audioContext.createMediaStreamSource(stream);

                this.processer.onaudioprocess = (e) => {
                  this.sendData(e.inputBuffer.getChannelData(0));
                };

                this.streamSource.connect(this.processer);

                this.processer.connect(this.audioContext.destination);
              },
              (e) => {
                alert("没有音频采集设备，请插入耳机！");
                console.log("error:", e);
              }
            );
        } else {
          navigator.getUserMedia({ audio: true, video: false }).then(
            (stream) => {
              this.mediaStream =
                this.audioContext.createMediaStreamSource(stream);
              this.recorder.onaudioprocess = (e) => {
                console.log("nodevice, recorder.onaujdioprocess......");
              };
            },
            (e) => {
              console.log("error:", e);
            }
          );
        }

        //打开websocket
        this.createWebSocket();

      } else {
        alert("You don't initialize......");
      }
    },
    closeAudio() {
      console.log("close audio...state:", this.state);
      if (this.state === "ing") {
        this.state = "pause";

        if (this.streamSource) this.streamSource.disconnect();
        if (this.processer) this.processer.disconnect();

        if (this.audioStream) {
          this.audioStream.getTracks().forEach((track) => {
            track.stop();
          });
          this.audioStream = null;
        }

        this.processer.onaudioprocess = null;
        this.processer = null;

        this.audioContext.close();
        this.audioContext = null;

        this.closeSocket()
      }
    },
    createWebSocket() {
      console.log("create websocket ...");

      let urlParam = this.handShakeParams();

      let url = this.uri + urlParam;
      console.log(url);
      if ("WebSocket" in window) {
        this.socket = new WebSocket(url);
      } else if ("MozWebSocket" in window) {
        this.socket = new MozWebSocket(url);
      } else {
        alert(notSupportTip);
        return null;
      }

      this.socket.onopen = (e) => {
        this.processWsOpen();
      };
      this.socket.onmessage = (e) => {
        this.processWsMessage(e);
      };
      this.socket.onerror = (e) => {
        console.log("关闭连接ws.onerror");
        if (!this.isRecording) {
          this.toggleRecordingB();
        }
      };
      this.socket.onclose = (e) => {
        console.log("关闭连接ws.onclose");
      };
    },
    handShakeParams() {
      let appId = this.appid;
      let secretKey = this.apiKey;
      let ts = Math.floor(new Date().getTime() / 1000);
      let signa = hex_md5(appId + ts);
      let signatureSha = CryptoJS.HmacSHA1(signa, secretKey);
      let signature = CryptoJS.enc.Base64.stringify(signatureSha);
      signature = encodeURIComponent(signature);
      return "?appid=" + appId + "&ts=" + ts + "&signa=" + signature;
    },
    sendData(buf) {
      this.recWorker.postMessage({
        command: "transform",
        buffer: buf,
      });
    },
    processWsOpen() {
      if (!this.socket) {
        console.warn("socket is null");
        return;
      }

      if (this.socket.readyState !== 1) {
        return;
      }

      var audioData = this.audioBuffer.splice(0, 1280);

      console.log(`audioData`, audioData);

      this.socket.send(new Int8Array(audioData));

      this.handlerInterval = setInterval(() => {
        // websocket未连接
        if (this.socket.readyState !== 1) {
          clearInterval(this.handlerInterval);
          return;
        }

        if (this.audioBuffer.length === 0) {
          if (this.state === "end") {
            this.ws.send('{"end": true}');
            console.log("发送结束标识");
            clearInterval(this.handlerInterval);
          }
          return false;
        }

        var audioData = this.audioBuffer.splice(0, 1280);
        if (audioData.length > 0) {
          this.socket.send(new Int8Array(audioData));
        }
      }, 40);
    },
    processWsMessage(e) {
      let jsonData = JSON.parse(e.data);
      if (jsonData.action == "started") {
        console.log("握手成功");
      } else if (jsonData.action == "result") {
        this.setResult(JSON.parse(jsonData.data));
      } else if (jsonData.action == "error") {
        console.log("出错了:", jsonData);
      }
    },
    setResult(data) {
      let rtasrResult = [];
      rtasrResult[data.seg_id] = data;
      rtasrResult.forEach((i) => {
        if (i.cn.st.type == 0) {
          let str = "";
          i.cn.st.rt.forEach((j) => {
            j.ws.forEach((k) => {
              k.cw.forEach((l) => {
                str += l.w.trim();
              });
            });
          });
          this.audioStr += str;

          // 使用 addMessage 添加到历史记录
          // if (this.currentRecordingIndex === null) {
          //   this.currentRecordingIndex = this.messages.length;
          //   this.addMessage(str, 'right');
          // } else {
          //   // 如果已有录音消息，则更新最后一条消息
          //   let lastIndex = this.messages.length - 1;
          //   this.messages[lastIndex].text += str;
          //   this.conversationHistory[this.conversationHistory.length - 1].content += str; // 同时更新历史记录
          // }
          this.$nextTick(() => {
            const container = this.$refs.chatContainer;
            container.scrollTop = container.scrollHeight;
          });
        }
      });
    },
  },
};
</script>


<style>
body,
html {
  height: 100vh;
  margin: 0;
  font-family: Arial, sans-serif;
  background: #1a1c29;
  position: relative;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

input {
  -webkit-user-select: auto;
}

textarea {
  -webkit-user-select: auto;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* 半透明黑色背景 */
  z-index: 100;
  /* 确保它位于其他内容之上 */
}


.delete-modal {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  z-index: 101;
  /* background-color: rgba(0, 0, 0, 0.4); */
}

.delete-options {
  background-color: white;
  border-radius: 15px 15px 0 0;
  /* 圆角只在顶部 */
  width: 100%;
  /* 宽度为100% */
  box-sizing: border-box;
  /* 确保内边距不影响总宽度 */
}

.delete-option {
  padding: 15px;
  text-align: center;
  border-bottom: 1px solid #ddd;
}

.delete-option:last-child {
  border-bottom: none;
}

.delete-option.confirm {
  color: red;
}

.delete-option.cancel {
  color: blue;
}

.tooltip-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}


.tooltip-top {
  top: -64px !important;
}

.tooltip-top::after {
  top: 50px !important;
  border-color: black transparent transparent transparent !important;
  /* 将红色部分放在顶部 */
}

.tooltip img {
  width: 16px !important;
  height: 16px !important;
  margin-top: 0px !important;
  margin-right: 0px !important;
  margin-left: 0px !important;
  border-radius: 0px !important;
}

.tooltip {
  position: absolute;
  /* top: 103%; */
  bottom: -64px;
  left: 50%;
  transform: translateX(-50%);
  border: 1px solid black;
  background-color: black;
  border-radius: 5px;
  padding: 5px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: space-around;
  color: white;
  /* 白色文字 */
  z-index: 1;
  width: 130px;
  height: 40px;
}

.tooltip::after {
  content: "";
  position: absolute;
  top: -13px;
  /* 小三角形的大小 */
  left: 50%;
  transform: translateX(-50%);
  border-width: 7px;
  border-style: solid;
  border-color: transparent transparent black transparent;
  /* 调整此处的 border-color 来改变小三角形的颜色 */
}

.tooltip button {
  border: none;
  background-color: #f0f0f0;
  padding: 5px 10px;
  border-radius: 3px;
  cursor: pointer;
}

.tooltip button:hover {
  background-color: #e0e0e0;
}


.mic-con {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  justify-content: center;
  margin-left: -20px;
}

.mic-con img {
  width: 50px;
  height: 50px;
}

.mic-status {
  top: 40%;
  left: 50%;
  position: fixed;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  box-sizing: border-box;
  text-align: center;
  padding: 25px 15px 15px 15px;
  width: 150px;
  height: 150px;
}

i {
  margin: 0;
  font-size: 60px;
}

p {
  margin-top: 15px;
}

.AiEnabled {
  border-radius: 10px;
  width: 70%;
  height: 70px;
  background-color: white;
  margin-top: 10%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  /* 添加此行来实现水平居中 */

}

.wave {
  padding: 0px;
  /* margin: 10px; */
  margin-top: 50px;
  margin-left: 10px;
}

.wave span {
  width: 15px;
  height: 2px;
  margin: -9px;
  display: block;
  background: white;
}

.wave span:first-child {
  animation: wave 0.3s infinite ease;
}

.wave span:nth-child(2) {
  animation: wave 0.4s infinite ease;
}

.wave span:nth-child(3) {
  animation: wave 0.5s infinite ease;
}

.wave span:nth-child(4) {
  animation: wave 0.6s infinite ease;
}

.wave span:nth-child(5) {
  animation: wave 0.7s infinite ease;
}

.wave span:nth-child(6) {
  animation: wave 0.8s infinite ease;
}

@keyframes wave {

  0%,
  100% {
    width: 4px;
  }

  30%,
  70% {
    width: 8px;
  }

  60% {
    width: 10px;
  }
}


.AiEnabled img {
  width: 45px;
  height: 45px;
  margin-right: 20px;
}

.app {
  height: 100vh;
}

.chat-container {
  padding: 10px;
  overflow-y: auto;
  /* min-height: 30vh;
  max-height: calc(100vh - 80px); */
  height: calc(100vh - 80px);
}

.message {
  display: flex;
  align-items: flex-start;
  /* 使头像在顶部对齐 */
  margin-bottom: 10px;
  position: relative;
}

.message.initial img,
.message.left img,
.message.right img {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  margin-top: 16px;
}


.message.initial,
.message.left {
  flex-direction: row;
}

.message.right {
  flex-direction: row-reverse;
  /* margin-left: 10px; */
}

.message.initial img,
.message.left img {
  margin-right: 25px;
}

.message.right img {
  margin-left: 25px;
}

.message .text {
  position: relative;
  /* 为小箭头设置基准 */
  max-width: 60%;
  padding: 10px;
  border-radius: 10px;
  background: white;
  font-size: 16px;
  margin-top: 16px;
}

.message.right .text {
  background: white;
}

/* 添加小箭头 */
.message .text::before {
  content: '';
  position: absolute;
  top: 12px;
  /* 调整箭头的垂直位置 */
  width: 0;
  height: 0;
  border: 15px solid transparent;
  /* 小箭头大小 */
}

.message.initial .text::before,
.message.left .text::before {
  left: -30px;
  /* 调整箭头的水平位置 */
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 20px solid white;
  /* 保持这个边框的原始大小 */
}

/* 右侧消息的小箭头 */
.message.right .text::before {
  right: -30px;
  /* 调整箭头的水平位置 */
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 20px solid white;
  /* border-left-color: white; */
}

.copy-success {
  display: none;
  /* 默认不显示 */
  position: fixed;
  /* 固定位置 */
  left: 50%;
  /* 水平居中 */
  top: 20px;
  /* 距离底部20px */
  transform: translateX(-50%);
  /* 确保完全居中 */
  padding: 10px 20px;
  background-color: #222543;
  /* 绿色背景 */
  color: white;
  /* 白色文字 */
  border-radius: 5px;
  z-index: 1000;
  /* 确保在顶层 */
}


.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  padding: 10px;
  background: #222543;
  align-items: center;
}

.record-button {
  width: 30px;
  height: 30px;
  border: none;
  margin-right: 10px;
}

.input-box-input {
  flex-grow: 1;
  padding: 2px;
  border: 1px solid #1b2036;
  border-radius: 20px;
  resize: none;
  overflow: auto;
  min-height: 35px;
  padding-left: 20px;
  background-color: #1b2036;
  color: white;
  font-size: 16px;
}

.input-box {
  flex-grow: 1;
  padding: 2px;
  border: 1px solid #1b2036;
  border-radius: 20px;
  resize: none;
  overflow: auto;
  min-height: 35px;
  padding-left: 20px;
  background-color: #1b2036;
  color: white;
  user-select: none;
  /* 标准语法 */
  -webkit-user-select: none;
  /* Safari 和 Chrome 浏览器 */
  -moz-user-select: none;
  /* Firefox 浏览器 */
  -ms-user-select: none;
  /* Internet Explorer/Edge 浏览器 */
  font-size: 16px;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal img {
  max-width: 50%;
  border-radius: 10px;
}


.problem-link {
  text-decoration: underline;
  cursor: pointer;
  color: #0091FF;
}

.send-button {
  padding: 5px 10px;
  margin-left: 10px;
  background-color: #4b5c77;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;
  width: 60px;
}

.send-button:hover {
  background-color: #657896;
}

.copy-link {
  display: flex;
  align-items: center;
  color: #4b5c77;
  cursor: pointer;
  font-size: 14px;
  margin-top: 10px;
  /* 调整间距 */
  text-decoration: none;
  justify-content: space-between;

  /* 去除下划线 */
}

.copy-icon-audio {
  width: 17px !important;
  /* 调整图标大小 */
  height: 17px !important;
  margin-right: 5px !important;
  margin-top: 0px !important;
}

.copy-icon {
  width: 16px !important;
  /* 调整图标大小 */
  height: 16px !important;
  margin-right: 5px !important;
  margin-top: 2px !important;
}

.radio-icon-w-l {
  width: 20px !important;
  /* 调整图标大小 */
  height: 20px !important;
  position: relative;
  top: 10px;
  left: 1%;
}

.radio-icon-w-r {
  width: 20px !important;
  /* 调整图标大小 */
  height: 20px !important;
  position: absolute;
  top: 10px;
  left: 1%;
  margin-left: 0px !important;
}
</style>