import CryptoJS from 'crypto-js'

class TTSClient {
    constructor({
        appId = '46bc0d37',
        apiSecret = 'MGQzZDlmNzhlYWQ4MmZkNThmMGQyMjM5',
        apiKey = '640113e9239fa1362ca5c4b2e1e9e69f',
        uid,
        webUrl,
        qa
    } = {}) {
        this.appId = appId;
        this.apiSecret = apiSecret;
        this.apiKey = apiKey;
        this.uid = uid
        this.ws = null;
        this.texts = [];
        this.isNew = true;
        this.webUrl = "wss://spark-api.xf-yun.com/v3.5/chat";
        this.qa = qa;
    }

    set onMessage(callback) {
        this.messageCallback = callback;
    }

    set onOver(callback) {
        this.overTextContent = callback;
    }

    getWebsocketUrl() {
        return new Promise((resolve, reject) => {
            var url = this.webUrl;
            var host = location.host
            var date = new Date().toGMTString()
            var algorithm = 'hmac-sha256'
            var headers = 'host date request-line'
            var signatureOrigin = `host: ${host}\ndate: ${date}\nGET /v3.5/chat HTTP/1.1`
            var signatureSha = CryptoJS.HmacSHA256(signatureOrigin, this.apiSecret)
            var signature = CryptoJS.enc.Base64.stringify(signatureSha)
            var authorizationOrigin = `api_key="${this.apiKey}", algorithm="${algorithm}", headers="${headers}", signature="${signature}"`
            var authorization = btoa(authorizationOrigin)
            url = `${url}?authorization=${authorization}&date=${date}&host=${host}`
            resolve(url)
        })
    }

    async connectWebSocket() {
        try {
            const url = await this.getWebsocketUrl();
            if (!('WebSocket' in window || 'MozWebSocket' in window)) {
                alert('浏览器不支持WebSocket');
                return false;
            }

            this.ws = 'WebSocket' in window ? new WebSocket(url) : new MozWebSocket(url);

            return new Promise((resolve, reject) => {
                this.ws.onopen = e => {
                    console.log('连接成功');
                    resolve(true);
                };
                this.ws.onmessage = e => this.handleMessage(e.data);
                this.ws.onerror = e => {
                    console.error(`WebSocket Error: ${e}`);
                    reject(false);
                };
                this.ws.onclose = e => {
                    //alert('AI 连接异常');
                    console.log('WebSocket Closed:', e);
                    resolve(false);
                };
            });
        } catch (error) {
            console.error('Connection Error:', error);
            return false;
        }
    }

    webSocketSend(historyPayload) {
        var params = {
            "header": {
                "app_id": this.appId,
                "uid": this.uid // 根据需要修改或动态生成
            },
            "parameter": {
                "chat": {
                    "domain": "generalv3.5",
                    "temperature": 0.5,
                    "max_tokens": 4096,
                    "auditing": "default"
                }
            },
            "payload": {
                "message": {
                    "text": this.qa.concat(historyPayload)
                }
            }
        }
        this.ws.send(JSON.stringify(params));
        this.isNew = true;
    }

    handleMessage(data) {
        let jsonData = JSON.parse(data);
        // 检查 choices 是否存在
        if (!jsonData.payload || !jsonData.payload.choices) {
            console.error('Error: choices is undefined');
            this.ws.close();
            return;
        }
        const text = jsonData.payload.choices.text;
        text.forEach(item => {
            this.texts.push(item.content);
        });
        if (jsonData.header.code !== 0) {
            console.error(`Error: ${jsonData.header.code}:${jsonData.header.message}`);
            return;
        }
        if(this.isNew){
            this.isNew = false;
            // 新的
            this.messageCallback(this.texts.join(''),'new');
        } else {
            this.isNew = false;
            // 修改的
            this.messageCallback(this.texts.join(''),'update');
        }
        this.texts = [];
        if (jsonData.header.code === 0 && jsonData.header.status === 2) {
            this.ws.close();
            console.log(this.texts.join(''));
            this.overTextContent();
            // if (this.messageCallback) {
            //     this.messageCallback(this.texts.join(''));
            // }
        }
    }

    async start() {
        this.texts = [];
        const isConnected = await this.connectWebSocket();
        return isConnected;
    }
}

export default TTSClient;